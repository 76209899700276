<template>
  <step-lesson-layout :title="$t('module1.part1.lesson3.title')" :title-annotation="$t('module1.part1.lesson3.titleAnnotation')" :disable-max-width="true">
    <template v-slot:content>
      <div class="module1-part1-lesson3">
        <div class="content-wrapper">
          <div class="row top-row">
            <!-- BLOCK 1 SUCCESS -->
            <app-type-block type="success" :title="$t('module1.part1.lesson3.block1.title')" :no-icon="true">
              <template v-slot:content>
                <div class="icon-block-content">
                  <div class="image-wrapper">
                    <img :src="require('@/assets/module1/part1/illu-mesure-incitative.svg')" :alt="$t('global.imageAlts.moneyIllu')">
                  </div>
                  <p class="paragraphe" v-html="$t('module1.part1.lesson3.block1.content')"></p>
                </div>
              </template>
            </app-type-block>

            <!-- BLOCK 2 INFO -->
            <app-type-block type="info" :title="$t('module1.part1.lesson3.block2.title')" :no-icon="true">
              <template v-slot:content>
                <div class="icon-block-content france-renov-block">
                  <div class="image-wrapper">
                    <img :src="require('@/assets/logos/logo-france-renov.svg')" :alt="$t('global.imageAlts.franceRenovLogo')">
                  </div>
                  <p class="paragraphe" v-html="$t('module1.part1.lesson3.block2.content')"></p>
                </div>
              </template>
            </app-type-block>

            <!-- BLOCK 3 ERRORS -->
            <app-type-block type="error" :title="$t('module1.part1.lesson3.block3.title')" :no-icon="true">
              <template v-slot:content>
                <div class="calendrier-block">
                  <div class="icon-block-content">
                    <div class="image-wrapper">
                      <img :src="require('@/assets/module1/part1/illu-obligations.svg')" :alt="$t('global.imageAlts.warning')">
                    </div>
                    <p class="paragraphe" v-html="$t('module1.part1.lesson3.block3.content')"></p>
                  </div>
                  <div class="button-wrapper">
                    <calendrier-application-modal></calendrier-application-modal>
                  </div>
                </div>
              </template>
            </app-type-block>
          </div>
          <div class="row">
            <!-- BLOCK 4 EN BREF -->
            <div class="app-text-block">
              <p class="title">{{ $t('module1.part1.lesson3.block4.title') }}</p>
              <p class="content paragraphe" v-html="$t('module1.part1.lesson3.block4.content')"></p>
            </div>
            <!-- BLOCK 5 START ACTIVITY -->
            <div class="app-decoration-block">
              <p class="content">
                {{ $t('module1.part1.lesson3.block5.content') }}
              </p>
              <app-button-layout @click="goToNextStep">
                {{ $t('training.global.goToActivity') }}
              </app-button-layout>
            </div>
          </div>
        </div>
      </div>
    </template>
  </step-lesson-layout>
</template>

<script>
import StepLessonLayout from '@/components/training/StepLessonLayout'
import AppTypeBlock from '@/components/layout/AppTypeBlock'
import AppButtonLayout from '@/components/buttons/AppButtonLayout'
import CalendrierApplicationModal from '@/components/training/module1/part1/CalendrierApplicationModal'
import useTrainingUtils from '@/utils/useTrainingUtils'

export default {
  name: 'Module1Part1Lesson3',
  components: { AppButtonLayout, AppTypeBlock, StepLessonLayout, CalendrierApplicationModal },
  setup () {
    const { goToNextStep } = useTrainingUtils()
    return { goToNextStep }
  }
}
</script>

<style lang="scss" scoped>
.module1-part1-lesson3 {
  padding: 0 $space-s;
  display: flex;
  flex-direction: column;
  align-items: center;
  .content-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    .row {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .top-row {
      display: grid;
      gap: $space-s;
    }
    .icon-block-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      .image-wrapper {
        margin-bottom: $space-m;
      }
    }
    .calendrier-block {
      .button-wrapper {
        margin-top: $space-sm;
        display: flex;
        justify-content: center;
      }
    }
  }
  .app-decoration-block {
    max-width: 364px;
    .app-button-layout {
      margin-top: $space-m;
    }
  }
}

@media (min-width: $bp-phone) {
  .module1-part1-lesson3 {
    .content-wrapper {
      padding: 0 $space-s;
      .icon-block-content:not(.france-renov-block) {
        flex-direction: row;
        align-items: flex-start;
        .image-wrapper {
          margin-bottom: 0;
          margin-right: $space-ml;
        }
      }
    }
  }
}

@media (min-width: $bp-tablet) {
  .module1-part1-lesson3 {
    .content-wrapper {
      .top-row {
        grid-template-columns: repeat(3, 1fr);
        .app-type-block {
          align-self: start;
        }
      }
      .icon-block-content:not(.france-renov-block) {
        flex-direction: column;
        align-items: center;
        .image-wrapper {
          margin-bottom: $space-m;
          margin-right: 0;
        }
      }
      .app-text-block {
        margin-right: $space-l;
      }
    }
  }
}
</style>
