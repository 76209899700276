<template>
  <app-modal theme="training">
    <template v-slot:content>
      <div class="calendrier-modal-content">
        <div class="step-wrapper">
          <div class="step">
            <div class="text-content">
              <div class="label">{{ $t('module1.part1.lesson3.block3.modal.label1') }}</div>
              <div class="title">{{ $t('module1.part1.lesson3.block3.modal.title1') }}</div>
            </div>
            <div class="image" v-html="require('@/assets/module1/part1/calendrier-modal-image1.svg?inline')"></div>
          </div>
        </div>
        <div class="step-wrapper">
          <div class="step">
            <div class="text-content">
              <div class="label">{{ $t('module1.part1.lesson3.block3.modal.label2') }}</div>
              <div class="title">{{ $t('module1.part1.lesson3.block3.modal.title2') }}</div>
            </div>
            <div class="image" v-html="require('@/assets/module1/part1/calendrier-modal-image2.svg?inline')"></div>
          </div>
        </div>
        <div class="step-wrapper">
          <div class="step">
            <div class="text-content">
              <div class="label">{{ $t('module1.part1.lesson3.block3.modal.label3') }}</div>
              <div class="title">{{ $t('module1.part1.lesson3.block3.modal.title3') }}</div>
            </div>
            <div class="image" v-html="require('@/assets/module1/part1/calendrier-modal-image3.svg?inline')"></div>
          </div>
        </div>
        <div class="step-wrapper">
          <div class="step">
            <div class="text-content">
              <div class="label">{{ $t('module1.part1.lesson3.block3.modal.label4') }}</div>
              <div class="title">{{ $t('module1.part1.lesson3.block3.modal.title4') }}</div>
            </div>
            <div class="image" v-html="require('@/assets/module1/part1/calendrier-modal-image4.svg?inline')"></div>
          </div>
        </div>
      </div>
    </template>
    <template v-slot:modal-button>
      <app-button-layout color="alternate-secondary" :small="true">
        {{ $t('module1.part1.lesson3.block3.modal.button') }}
      </app-button-layout>
    </template>
  </app-modal>
</template>

<script>
import AppModal from '@/components/layout/AppModal'
import AppButtonLayout from '@/components/buttons/AppButtonLayout'
export default {
  name: 'CalendrierApplicationModal',
  components: { AppButtonLayout, AppModal }
}
</script>

<style lang="scss" scoped>
.calendrier-modal-content {
  display: flex;
  flex-direction: column;
  .step-wrapper {
    & + .step-wrapper {
      border-top: 1px solid $c-border;
    }
    &:first-child {
      .title {
        color: #20b8bc;
      }
    }
  }
  .step {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: $space-l 0;
    .text-content {
      display: flex;
      flex-direction: column;
    }
    .label {
      font-size: $fz-xl;
      font-weight: $fw-l;
    }
    .title {
      color: $c-error;
      font-size: $fz-l;
      font-weight: $fw-l;
    }
    .image {
      margin-top: $space-l;
      height: 112px;
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        width: 100%;
      }
    }
  }
}

@media (min-width: $bp-tablet) {
  .calendrier-modal-content {
    flex-direction: row;
    .step-wrapper {
      & + .step-wrapper {
        border-top: none;
        border-left: 1px solid $c-border;
      }
    }
    .step {
      box-sizing: border-box;
      padding: $space-m $space-s;
      flex: 1 1 0;
    }
  }
}
</style>

<style lang="scss">
.calendrier-modal-content {
  .step {
    .image {
      svg {
        width: 100%;
      }
    }
  }
}
</style>
