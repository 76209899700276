<template>
  <div class="open-modal-button" @click="openModal">
    <slot name="modal-button">
      <app-button-layout>
        {{ buttonLabel }}
      </app-button-layout>
    </slot>
  </div>
  <teleport to="#modals">
    <div class="modal-wrapper" v-if="modalOpen" @click.self="closeModal" :class="{ [theme]: true }">
      <app-button-layout @click="closeModal" color="white" v-if="theme === 'training'" class="training-close">
        {{ $t('global.navigation.close') }}
      </app-button-layout>
      <div class="modal">
        <div class="head" v-if="theme !== 'training'">
          <slot name="head">
            <p class="title">
              {{ title }}
            </p>
            <p @click="closeModal" class="close-cross">
              <img :src="require('@/assets/icons/cross.svg')" :alt="$t('global.imageAlts.crossIcon')">
            </p>
          </slot>
        </div>
        <div class="content">
          <slot name="content" v-bind:closeModalFn="closeModal"></slot>
        </div>
      </div>
    </div>
  </teleport>
</template>

<script>
import AppButtonLayout from '@/components/buttons/AppButtonLayout'

export default {
  name: 'AppModal',
  components: { AppButtonLayout },
  emits: ['closed', 'opened'],
  props: {
    title: {
      type: String,
      required: false
    },
    buttonLabel: {
      type: String,
      required: false
    },
    theme: {
      type: String,
      required: false
    }
  },
  data () {
    return {
      modalOpen: false
    }
  },
  methods: {
    openModal () {
      this.modalOpen = true
      this.$emit('opened')
    },
    closeModal () {
      this.modalOpen = false
      this.$emit('closed')
    }
  }
}
</script>

<style lang="scss" scoped>
.modal-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba($c-border, 0.9);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  cursor: pointer;
  .modal {
    cursor: default;
    width: 100%;
    height: 100%;
    background-color: #fff;
    padding: $space-m;
    border-radius: $radius;
    .head {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: $space-l;
      .title {
        padding: 0 $space-l;
        font-weight: $fw-l;
        font-size: $fz-xl;
        color: $c-primary;
        text-align: center;
      }
      .close-cross {
        cursor: pointer;
        position: absolute;
        right: $space-s;
        img {
          width: 25px;
        }
      }
    }
  }
  &.training {
    background-color: $c-text;
    display: flex;
    flex-direction: column;
    align-items: center;
    .training-close {
      margin: $space-s;
      width: 100%;
      max-width: 320px;
    }
    .modal {
      height: auto;
      overflow-y: auto;
      margin-top: $space-s;
    }
  }
}

@media (min-width: $bp-tablet) {
  .modal-wrapper {
    .modal {
      width: auto;
      min-width: $bp-phone;
      height: auto;
      max-width: $bp-tablet;
      max-height: 100%;
      overflow-y: auto;
    }
    &.training {
      flex-direction: column-reverse;
      .modal {
        max-width: $bp-desktop;
      }
    }
  }
}
</style>
