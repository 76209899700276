<template>
  <div class="app-type-block" :class="{ [type]: true }">
    <div class="head">
      <slot name="head">
        {{ title }}
        <img v-if="!noIcon" :src="icon" :alt="$t('global.imageAlts.default')" class="icon">
      </slot>
    </div>
    <div class="content">
      <slot name="content"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppTypeBlock',
  props: {
    title: {
      type: String,
      required: false
    },
    type: {
      type: String,
      required: false,
      default: 'info',
      validation (value) {
        return ['success', 'info', 'error'].includes(value)
      }
    },
    noIcon: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    icon () {
      const icons = {
        success: require('@/assets/icons/thumb-up.svg'),
        info: require('@/assets/icons/chat.svg'),
        error: require('@/assets/icons/forbidden.svg')
      }
      return icons[this.type]
    }
  }
}
</script>

<style lang="scss" scoped>
.app-type-block {
  background-color: #fff;
  border-radius: 0 $radius-xl $radius-xl $radius-xl;
  .head {
    padding: $space-m;
    color: #fff;
    border-radius: 0 $radius-xl 0 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: $ff-big;
    font-size: $fz-xl;
    font-weight: $fw-l;
    word-break: break-word;
    .icon {
      margin-left: $space-xs;
    }
  }
  .content {
    padding: $space-m;
  }
  &.success {
    .head {
      background-color: $c-primary;
    }
  }
  &.info {
    .head {
      background-color: $c-secondary;
    }
  }
  &.error {
    .head {
      background-color: $c-error;
    }
  }
}
</style>
